import React from "react";
// import "../../../App.css";
import Logo from "../../../assets/images/logo.png";
import ordernowimgr from "../../../assets/images/ordernow-imgr.svg";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  h5,
  Button,
} from "react-bootstrap";
import { useMediaQuery } from "@material-ui/core";
import "../../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import parseHtml from "html-react-parser";

export const MenuNavigation = () => {
  const history = useHistory();
  const [cookieSIC, setCookieSIC, removeCookieSIC] = useCookies([
    "sic_name",
    "sic_user_id",
  ]);

  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <>
      <Navbar
        bg="black"
        variant="dark"
        expand="lg"
        className="sticky-sm-top navBarKeto"
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand href="/home">
          <img
            width="100%"
            height="100%"
            title="Bread + Salt Logo"
            className="App-logo"
            src={Logo}
            alt="Bread + Salt Logo"
            loading="lazy"
          />
        </Navbar.Brand>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">Home</Nav.Link>
            {
              <Nav.Link
                href="#"
                onClick={() => {
                  history.push("/about");
                }}
              >
                About Us
              </Nav.Link>
            }
            {/* <Nav.Link
              href="#"
              onClick={() => {
                history.push("/menu");
              }}
            >
              Order Now
            </Nav.Link> */}
            {/* <Nav.Link
              href="#"
              onClick={() => {
                history.push("/team");
              }}
            >
              Team
            </Nav.Link> */}
            <Nav.Link
              href="#"
              onClick={() => {
                history.push("/contact");
              }}
            >
              Contact
            </Nav.Link>
            {/* <Nav.Link
              onClick={() => {
                history.push(
                  cookieSIC.sic_user_id > 0 ? "/rewards" : "/register"
                );
              }}
            >
              {cookieSIC.sic_user_id > 0 ? "eLoyalty" : "Join AJ's Club"}
            </Nav.Link> */}
            <Nav.Link
              href="#"
              onClick={() => {
                history.push("/photo-gallery");
              }}
            >
              Photo Gallery
            </Nav.Link>
            <Nav.Link
              className="red"
              // style={{color: "#f11d25 !important"}}
              href="#"
              onClick={() => {
                history.push("/menu");
              }}
            >
              Order Now
            </Nav.Link>
            {/*<Nav.Link href="#">Gift Cards</Nav.Link>
            <Nav.Link href="#">Team</Nav.Link>
            <Nav.Link href="#">Join Our Loyalty Program</Nav.Link>*/}
          </Nav>
          <span className="white topbtn">
            {!isMobile
              ? cookieSIC.sic_firstName
                ? parseHtml(
                  "Welcome back <b>" + cookieSIC.sic_firstName + "!</b>"
                )
                : null
              : null}
          </span>
          {/* <a href="/menu" className="topbtn">
            <img src={ordernowimgr} />
          </a> */}
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

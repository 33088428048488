import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Button, h5, p, h2, div } from "react-bootstrap";
import { AddressMap } from "../components/AddressMap";
import { MenuNavigation } from '../components/MenuNavigation';
import { Footer } from '../components/Footer';
import BlankSpace from "../components/BlankSpace";
import PhotoGalleryList from "../components/PhotoGalleryList";
import React, { useEffect } from 'react'
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from "@material-ui/core/Typography";

export const PhotoGallery = () => {

  // useEffect(() => {
  // document.title = "Photo Gallery"
  // }, [])    

  return (

    <div className="PhotoGallery">
      <MenuNavigation />
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Photo Gallery: Breakfast & Brunch Restaurant</title>
          <link rel="canonical" href="/photo-gallery" />
          <meta
            name="description"
            content="Contemporary bistro featuring classic & creative breakfast & lunch fare, plus outdoor patio tables."
          />
        </Helmet>
      </HelmetProvider>
      <div className="HeaderImg">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12}>
              <h1 className="gold center">Explore our Photo Gallery</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <BlankSpace />
      <Container>
        <Col xs={10} className="center">
          <Typography component="h2" variant="h6" className="title whitespace">Contemporary bistro featuring classic & creative breakfast & lunch fare, plus outdoor patio tables.</Typography>
        </Col>
        <BlankSpace />
        <Row className="whitespace">
          <Col>
            <PhotoGalleryList />
          </Col>
        </Row>
      </Container>
      <BlankSpace />
      {/* <AddressMap /> */}
      <Footer />
    </div>

  );
};

import { useEffect, useState } from "react";
import Logo from "../../../assets/images/logo.png";
import CookieIcon from "../../../assets/images/cookie-icon.svg";
import CookieConsent from "react-cookie-consent";
import { FaHeart } from "react-icons/fa";
import { Container, Button, Col } from "react-bootstrap";
import { SocialConnect } from "./pages/homepage/SocialConnect";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";

import packageJson from "../../../../package.json";

export const Footer = () => {
  const history = useHistory();
  return (
    <div>
      <SocialConnect />

      <div className="footer-area padding-top-40">
        <div className="footer-top d-md-block">
          <div className="container">
            <div className="row align-items-center justify-content-between padding-bottom-25">
              <div className="col-lg-3 col-md-3">
                <div className="f-logo">
                  <a href="/home">
                    {" "}
                    <img
                      width="100%"
                      height="100%"
                      title="Bread + Salt Frisco Logo"
                      className="App-logo"
                      src={Logo}
                      alt="Bread + Salt Frisco Logo"
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="f-title center">
                  <Typography variant="h4" gutterBottom component="div">
                    <span>Fresh ingredients</span> and <span>great service</span>. Visit us today!
                  </Typography>
                </div>
              </div>
              <div className="col-lg-2 col-md-3">
                <Button className="btn-red" href="/menu">
                  Order Now{" "}
                </Button>
              </div>
            </div>
            <hr></hr>
          </div>
        </div>
        <div className="footer-bottom padding-top-22 padding-bottom-30">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
                <div className="widget">
                  <Typography
                    className="gold padding-bottom-10"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    Address
                  </Typography>
                  <p>
                    Bread + Salt Frisco - Restaurant <br />
                    <a
                      aria-label="Google Map"
                      href="https://goo.gl/maps/6ybWRjkeFWRVJRWt7"
                      className="red-link"
                      target="_blank"
                    >
                      401 E Main St, Frisco, CO 80443, United States
                    </a>
                    <br />
                    <a
                      aria-label="Call Phone Number +1 970-389-5621"
                      href="tel:+1 970-389-5621"
                      className="red-link"
                      target="_blank"
                    >
                      P: +1 970-389-5621
                    </a>
                  </p>
                  <a
                    href="https://goo.gl/maps/6ybWRjkeFWRVJRWt7"
                    className="red-link"
                    target="_blank"
                  >
                    view location
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
                <div className="widget">
                  <Typography
                    className="gold padding-bottom-10"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    Service options
                  </Typography>
                  <p>Dine-in · Takeout</p>
                  <a href="/menu" className="red-link">
                    order now
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
                <div className="widget">
                  <Typography
                    className="gold padding-bottom-10"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    Business Hours
                  </Typography>
                  <p>
                    <span>Monday - Sunday: 7:00AM - 2:00PM</span>
                    <br></br>
                  </p>
                  <a
                    aria-label="Call Phone Number +1 970-389-5621"
                    href="tel:+1 970-389-5621"
                    className="red-link"
                    target="_blank"
                  >
                    make a call
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
                <div className="widget heart">
                  <p>
                    &copy; {new Date().getFullYear()} Bread + Salt Frisco by
                    ServingIntel, made with{" "}
                    <span>
                      <FaHeart />{" "}
                    </span>{" "}
                    for a better web. Version {packageJson.version}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Container className="footer">
          <p className="privacy">
            <a
              href="#"
              onClick={() => {
                history.push("/privacy-policy");
              }}
            >
              Privacy Policy
            </a>
          </p>
        </Container>
      </div>

      <CookieConsent
        location="bottom"
        buttonText="Accept all cookies"
        cookieName="myAwesomeCookieName2"
        buttonClasses="btn btn-red"
        style={{ background: "#000" }}
        buttonStyle={{ fontSize: "16px" }}
        expires={365}
      >
        <div className="center">
          <Col xs={3} className="padding-bottom-10 center">
            <img
              width="100%"
              height="100%"
              title="Accept all cookies Icon"
              src={CookieIcon}
              size="15"
              alt="Accept all cookies Icon"
              loading="lazy"
            />
          </Col>
          <p>
            <h3 className="center"> Your Privacy </h3>
          </p>
        </div>

        <Typography>
          Greetings! Our website uses cookies so we may better serve you. By
          clicking “Accept all cookies” and by continuing to browse our site you
          are agreeing to our{" "}
        </Typography>
        <Typography>
          <a
            href="#"
            onClick={() => {
              history.push("/terms-and-conditions");
            }}
          >
            Terms and Conditions
          </a>
        </Typography>
      </CookieConsent>
    </div>
  );
};

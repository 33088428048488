import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

export default function BusinessCard() {
  return (
    <Card>
      <CardActionArea
        className="color-scheme"
        sx={{ cursor: "default !important", pointerEvents: "none" }}
      >
        <CardMedia
          component="img"
          height="140"
          image="/static/business-hours.png"
          alt="Business Hours"
          width="100%"
          loading="lazy"
          title="Bread + Salt Frisco Business Hours"
        />
        <CardContent>
          <Typography gutterBottom variant="h4" component="div">
            Business Hours
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            Monday - Sunday: 7:00AM - 2:00PM
            <br></br>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

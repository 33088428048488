import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function ImgListCard() {
  return (
    <ImageList
      sx={{ width: 500, height: 450 }}
      variant="quilted"
      cols={4}
      rowHeight={121}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.alt}
            loading="lazy"
            title={item.title}
            width="100%"
            height="100%"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: '/static/1.jpg',
    title: 'Bread + Salt Frisco Image001',
    rows: 2,
    cols: 2,
    alt: 'Bread + Salt Frisco Image1',
  },
  {
    img: '/static/2.jpg',
    title: 'Bread + Salt Frisco Image002',
    alt: 'Bread + Salt Frisco Image2',
  },
  {
    img: '/static/3.jpg',
    title: 'Bread + Salt Frisco Image003',
    alt: 'Bread + Salt Frisco Image3',
  },
  {
    img: '/static/4.jpg',
    title: 'Bread + Salt Frisco Image004',
    cols: 2,
    alt: 'Bread + Salt Frisco Image4',
  },
  {
    img: '/static/5.jpg',
    title: 'Bread + Salt Frisco Image005',
    cols: 2,
    alt: 'Bread + Salt Frisco Image5',
  },
  {
    img: '/static/6.jpg',
    title: 'Bread + Salt Frisco Image006',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
    alt: 'Bread + Salt Frisco Image6',
  },
  {
    img: '/static/7.jpg',
    title: 'Bread + Salt Frisco Image007',
    alt: 'Bread + Salt Frisco Image7',
  },
  {
    img: '/static/8.jpg',
    title: 'Bread + Salt Frisco Image008',
    alt: 'Bread + Salt Frisco Image8',
  },
  {
    img: '/static/9.jpg',
    title: 'Bread + Salt Frisco Image009',
    rows: 2,
    cols: 2,
    alt: 'Bread + Salt Frisco Image9',
  },
  {
    img: '/static/10.jpg',
    title: 'Bread + Salt Frisco Image010',
    alt: 'Bread + Salt Frisco Image10',
  },
  {
    img: '/static/11.jpg',
    title: 'Bread + Salt Frisco Image011',
    alt: 'Bread + Salt Frisco Image11',
  },
  {
    img: '/static/12.jpg',
    title: 'Bread + Salt Frisco Image012',
    cols: 2,
    alt: 'Bread + Salt Frisco Image12',
  },

];
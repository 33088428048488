import "../../../App.css";
import {
  Container,
  Row,
  Col,
  Button,
  h5,
  h1,
  h6,
  p,
  h2,
  div,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
import { MenuNavigation } from "../components/MenuNavigation";
import { Footer } from "../components/Footer";
import aboutimg1 from "../../../assets/images/about-img-01.jpg";
import aboutimg2 from "../../../assets/images/about-img-02.jpg";
import aboutimg3 from "../../../assets/images/about-img-03.jpg";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from "@mui/material/Typography";

export const About = () => {
  useEffect(() => {
    document.title = "About Us: Casual Breakfast, Lunch and Dinner on the weekends with Fresh ingredients and great service.";
    ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="About">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>About Us: Casual Breakfast, Lunch and Dinner on the weekends with Fresh ingredients and great service. </title>
          <link rel="canonical" href="/about" />
          <meta
            name="description"
            content="Bread and salt is a welcome greeting ceremony in many European cultures, a traditional sign of hospitality."
          />
        </Helmet>
      </HelmetProvider>
      <MenuNavigation />
      <div className="HeaderImg about-info">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={10}>
              <Typography variant="body1" className="white">
                Contemporary bistro with a diverse breakfast and lunch menu, including outdoor seating.{" "}
              </Typography>
              <br />
              <h1 className="gold">
                Bread: A symbol of sustenance and prosperity.
                <br></br>
                Salt: Adding zest to life's flavors.
              </h1>
            </Col>
            <Col xs={10}>
              <Typography variant="body1" className="white">
                {" "}
                While we are a classical breakfast place, we respect seasonality and influence of fresh ingredients. We use Colorado grown products whenever possible and we have a commitment to creating a harmonious and symbiotic relationship with you, our guests.
              </Typography>
              <br />
              <Button
                className="btn-red margin-top-10 margin-bottom-10"
                href="/menu"
              >
                Order Now{" "}
              </Button>
            </Col>
            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <div className="col-lg-4">
            <div class="about-info-left">
              <h2>Highlights from the Business</h2>
              <ul className="paddingSpecial">
                <li>Family-owned & Operated</li>
                <li>Breakfast & Brunch Restaurant</li>
                <li>Dine-in and Outdoor seating</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="about-info-right">
              <Typography variant="h4">
                Bread and salt is welcome greeting ceremony in many European cultures.
              </Typography>
              <Typography variant="boby1">
                {" "}
                <strong>A traditional sign of hospitality:</strong> Bread - So you never go hungry. Salt - So there is always spice in your life. While we are classical breakfast place, we respect seasonality and influence of fresh ingredients, We use Colorado grown products whenever possible and we have commitment to creating a harmonious and symbiotic relationship with you.....Our guests.{" "}
              </Typography>
              <br />

            </div>
            <div className="inner-about-gallery margin-top-30 ">
              <div className="col-lg-12">
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title="Bread + Salt Frisco About Us Image1"
                    src={aboutimg1}
                    alt="Bread + Salt Frisco About Us Image1"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title="Bread + Salt Frisco About Us Image2"
                    src={aboutimg2}
                    alt="Bread + Salt Frisco About Us Image2"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title="Bread + Salt Frisco About Us Image3"
                    src={aboutimg3}
                    alt="Bread + Salt Frisco About Us Image3"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <col className="whitespace"></col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
export default withRouter(About);
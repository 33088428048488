import ReactPlayer from "react-player";
import "../../../App.css";
import {
  Container,
  Card,
  Row,
  Col,
  h5,
  p,
  h2,
  h3,
  div,
  Alert,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from "@mui/material/Typography";

export const OrderSlider = () => {
  return (
    <div className="OrderSlider">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Order Now: Browse, view, and track your order.
          </title>
          <link rel="canonical" href="/menu" />
          <meta
            name="description"
            content="Casual Breakfast, Lunch and Dinner on the weekends with Fresh ingredients and great service."
          />
        </Helmet>
      </HelmetProvider>
      <div className="OrderHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col sm={12} lg={10}>
              <h1 className="left gold">A traditional sign of hospitality</h1>
              <h3 className="white">
                {" "}
                Casual Breakfast, Lunch and Dinner on the weekends with Fresh ingredients and great service.
              </h3>
              {/* <h2 className="left gold whitespace">
                Wheat-free, gluten-free, sugar-free, & soy-free.
              </h2>
              <Typography variant="body1" className="white">
                {" "}
                A particular focus is given to low or no 'carb' items, creating
                preservative-free offerings, made fresh daily, and truly
                satisfying.
              </Typography> */}
            </Col>
            {/* <Col sm={0} lg={4}>
              <Card>
                <Card.Img src="/static/1.jpg" />
              </Card>
            </Col> */}
            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
import "../../../App.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";

export const ContactSlider = () => {
  return (
    <div className="ContactSlider">
      <div className="ContactHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12} lg={10}>
              <Typography className="white">
                {" "}
                A classic breakfast and lunch restaurant, serving fresh food with Colorado grown ingredients.
              </Typography>
              <h1 className="left gold blankspace">
                Breakfast & Brunch, Coffee & Tea, Sandwiches
              </h1>
              <Typography className="white blankspace">
                {" "}
                <strong>Phone:</strong> (719) 966-5089 | <strong>Open</strong> 7:00 AM - 2:00 PM
              </Typography>
              <Typography className="white blankspace">
                {" "}
                Bread and Salt is a pet friendly restaurant in Frisco.
              </Typography>
            </Col>
            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
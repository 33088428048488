import { CarouselItem, CarouselCaption, Carousel, img } from "react-bootstrap";
import Slider1 from "../../../../../assets/images/header_img_1.jpg";
import Slider2 from "../../../../../assets/images/header_img_2.jpg";
import Slider3 from "../../../../../assets/images/header_img_3.jpg";
import Slider4 from "../../../../../assets/images/header_img_4.jpg";
import Slider5 from "../../../../../assets/images/header_img_5.jpg";
import React, { useEffect } from 'react'

export const HomepageSlider = () => {

  useEffect(() => {
    document.title = "Breakfast Near Me | Breakfast Restaurant in Frisco, CO"
  }, [])

  return (
    <Carousel fade>
      <Carousel.Item>
        <img width="100%" height="100%" title="Bread" className="d-block w-100" src={Slider1} alt="So you never go hungry and a sign of prosperity." loading="lazy" />
        <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
            <h1>Bread</h1>
            <p>So you never go hungry and a sign of prosperity.</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" height="100%" title="Salt" className="d-block w-100" src={Slider2} alt="So there is always spice in your life." loading="lazy" />
        <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
            <h1>Salt</h1>
            <p>So there is always spice in your life.</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" height="100%" title="Bread + Salt Restaurant" className="d-block w-100" src={Slider3} alt="A classic breakfast and lunch restaurant, serving fresh food with Colorado grown ingredients." loading="lazy" />
        <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
            <h1>Bread + Salt Restaurant</h1>
            <p>A classic breakfast and lunch restaurant, serving fresh food with Colorado grown ingredients.</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" height="100%" title="Located in Frisco, CO!" className="d-block w-100" src={Slider4} alt="We use Colorado grown products whenever possible and we have a commitment to creating a harmonious and symbiotic relationship with you, our guests." loading="lazy" />
        <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
            <h1>Located in Frisco, CO!</h1>
            <p>We use Colorado grown products whenever possible and we have a commitment to creating a harmonious and symbiotic relationship with you, our guests.</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" height="100%" title="Breakfast in Colorado" className="d-block w-100" src={Slider5} alt="Eating breakfast with loved ones is a great way to start the day." loading="lazy" />
        <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
            <h1>Breakfast in Colorado</h1>
            <p>Eating breakfast with loved ones is a great way to start the day.</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};
